import { DeleteModal } from "../../components/modal/DeleteModal.tsx";
import type { DeleteMetalMutation } from "./LeaseUserView.tsx";
import type { MetalListItemType } from "./metalQuery.ts";

export interface MetalDeleteModalProps {
    metal: MetalListItemType;
    deleteMutation: DeleteMetalMutation;
}

export function MetalDeleteModal({ metal, deleteMutation }: MetalDeleteModalProps) {
    const { display_name } = metal;

    async function deleteAction() {
        await deleteMutation.mutateAsync({ uuid: metal.uuid, location: metal.location });
    }

    return (
        <DeleteModal title="Delete Resource" modalAction={deleteAction}>
            Deleting a resource "{display_name}" will permanently remove it and will not be accessible after that.
        </DeleteModal>
    );
}
