import { createLazyFileRoute } from "@tanstack/react-router";
import { LeaseUserView } from "../../../../modules/metal/LeaseUserView.tsx";

export const Route = createLazyFileRoute("/_main/metal/lease/$uuid/")({
    component: Component,
});

function Component() {
    const { uuid } = Route.useParams();
    return <LeaseUserView key={uuid} bareId={uuid} />;
}
